import { Component, Prop, Mixins, ProvideReactive } from 'vue-property-decorator';
import template from './EditTemplate.Template.vue';
import TemplateOperationsEditTemplateValidator from './EditTemplate.Validator';
import FormBuilder from '@/formsGenerator/FormBuilder.vue';
import { ApiService } from '@/services/base/ApiService';
import { AxiosResponse } from 'axios';
import { TemplateTypeBlocReferentiel, TemplateTypeChampReferentiel } from '@/models';
import { Result } from '@/shared/models';
import { Getter, Action } from 'vuex-class';
import { ReferentielStoreMethods, getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { ValeurReferentielle, TypeValeurReferentielle } from '@/shared/models';
import { Profils } from '@/shared/enums';

@Component({
    ...template,
    name: 'TemplateOperationsEditTemplate',
    components: {
        FormBuilder,
    },
    inject: []
})
export default class TemplateOperationsEditTemplate extends Mixins(TemplateOperationsEditTemplateValidator) {

    public $refs!: { form: HTMLFormElement };
    @Prop({ default: '' }) public readonly idTemplateOperation!: string;

    // Met à disposition l'énumération des profils.
    public Profils = Profils;

    public formConfig: object = {};
    public loading: boolean = false;
    public loadingConfig: boolean = true;

    @Getter(getterKeyReferentiel(TypeValeurReferentielle.TemplateTypeBloc))
    public sections: TemplateTypeBlocReferentiel[];
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.TemplateTypeChamp))
    public champs: TemplateTypeChampReferentiel[];
    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
    public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>;

    @ProvideReactive('dateRai')
    public dateRai: string = null;

    public created(): void {
        this.getValeursReferentielles(TypeValeurReferentielle.TemplateTypeBloc);
        this.getValeursReferentielles(TypeValeurReferentielle.TemplateTypeChamp);
    }

    public mounted(): void {
        this.getTemplateOperationConfig();
    }


    public getTemplateOperationConfig() {
        this.$http.get(`/templateOperations/${this.idTemplateOperation}/config`).then((result: any) => {
            const { data: { data: { formConfig: config } } } = result;
            this.formConfig = config;
        }).finally(() => this.loadingConfig = false);
    }

    public getReferentiel<T>(url: string) {
        const referentielService = new ApiService<T>(url);
        return new Promise<AxiosResponse<Result<T[]>>>((resolve, reject) => {
            return referentielService.getAll()
                .then((response: any) => resolve(response.data.data))
                .catch((error: { response: any; }) => reject(error.response));
        });
    }

    private buildFormConfig(): any {
        const formConfigBuilded = this.formConfig;
        // Affectation du template operation Id.
        (formConfigBuilded as any).templateOperationId = this.idTemplateOperation;

        (formConfigBuilded as any).sections.forEach((section: any, sectionIndex: number) => {
            const sectionTypeBloc = this.sections.find((templateTypeBloc: TemplateTypeBlocReferentiel) => templateTypeBloc.code === section.code);
            const templateTypeBlocId = section.templateTypeBlocId || (sectionTypeBloc || { id: 0 }).id;
            section.templateOperationId = this.idTemplateOperation;
            section.templateTypeBlocId = templateTypeBlocId;
            // Rebuild index.
            section.order = sectionIndex;
            section.ordre = sectionIndex;
            // Maj schema.
            if (section.schemas && section.schemas.length) {
                section.schemas.forEach((schema: any, schemaIndex: number) => {
                    schema.templateOperationId = this.idTemplateOperation;
                    schema.templateTypeBlocId = templateTypeBlocId;
                    // Rebuild index.
                    schema.order = schemaIndex;
                    schema.ordre = schemaIndex;
                    // Maj children (car un champ peut en contenir plusieurs) + insertion du parent Id.
                    if (schema.children && schema.children.length) {
                        schema.children.forEach((child: any, childSchemaIndex: number) => {
                            child.parentId = (parseInt(section.id, 10) > 0) ? section.id : null;
                            child.templateOperationId = this.idTemplateOperation;
                            child.templateTypeBlocId = templateTypeBlocId;
                            // Rebuild index.
                            child.order = childSchemaIndex;
                            child.ordre = childSchemaIndex;
                        });
                    }
                });
            }
        });
        return formConfigBuilded;
    }

    private submit() {
        if (this.$refs.form.validate()) {
            const formConfigBuilded = this.buildFormConfig();
            this.loading = true;
            this.$http.put(`/templateOperations/${this.idTemplateOperation}/editTemplate`, {
                formConfig: formConfigBuilded,
            }).finally(() => this.loading = false);
        }
    }

    private clear(): void {
        this.$refs.form.reset();
        this.loading = false;
    }
}


